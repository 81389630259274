import React from 'react';
import styled from 'styled-components';
import {message} from 'antd';
import * as AppActions from '../../AppActions';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import qs from 'query-string';
import GenericForm from 'rev.sdk.js/Generic/Form';
import Config from '../../../data.json';

const FormSpec = {
  schema: {
    title: '',
    type: 'object',
    required: ['uid'],
    properties: {
      uid: {
        type: 'string',
        title: 'UID',
        readOnly: true,
      },
      nftdata: {
        type: 'object',
        title: '',
        properties: {
          name: {
            type: 'string',
            title: '名稱',
          },
          image: {
            type: 'string',
            title: '圖片',
          },
          description: {
            type: 'string',
            title: '簡介',
          },
        },
      },
    },
  },
  uiSchema: {
    nftdata: {
      image: {
        'ui:widget': 'rev-single-image-uploader',
      },
      description: {
        'ui:widget': 'textarea',
      },
    },
  },
};

export default function EditTagPage(props) {
  const {uid, token, is_app} = qs.parse(props.location.search);
  const [tag, setTag] = React.useState({uid});

  /*
  React.useEffect(() => {
    async function fetchData() {
      AppActions.setLoading(true);
      try {
        setTag(await JStorage.fetchOneDocument('tag', {uid}));
      } catch (ex) {
        console.warn('EditTagPage ex', ex);
        alert('Not found!');
        setTag({});
      }
      AppActions.setLoading(false);
    }

    fetchData();
  }, [uid]);

  if (!tag) {
    return <Wrapper />;
  }
  */

  return (
    <Wrapper>
      <h1>編輯我的Tag</h1>
      <div className="content">
        <GenericForm
          {...FormSpec}
          instance={tag}
          onSubmit={async (formData, extValues) => {
            AppActions.setLoading(true);
            try {
              await JStorage.createDocument('tag', {...formData}, {token});
              message.success('上傳成功!');
              if (is_app) {
                window.location.href = `${Config.mobileAppHost}://web-callback?type=edit`;
              }
            } catch (ex) {
              console.warn(ex);
              message.error('發生錯誤, 無法進行編輯！');
            }
            AppActions.setLoading(false);
          }}
        />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #eee;
  padding: 80px 20px;
  min-height: 100vh;

  & > h1 {
    text-align: center;
  }

  & > .content {
    max-width: 720px;
    margin: 0 auto;
    border-radius: 16px;
    padding: 20px;
    background-color: white;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  }
`;
